// Navbar.js
import React from 'react';
import './Navbar.css'; // Import the Navbar CSS

function Navbar() {
  return (
    <div className="navbar">
      <a href=""><span className="UMEDX">U</span>MEDX</a>
      {/* <a href="#about">About</a>
      <a href="#services">Services</a>
      <a href="#contact">Contact</a> */}
    </div>
  );
}

export default Navbar;
